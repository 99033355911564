@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1140px !important;
  }
}

body {
  overflow-x: hidden;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(234, 54, 40, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important; 
}

.navbar-light .navbar-toggler {
  border-color: rgba(234, 54, 40, 0.5) !important;
} 