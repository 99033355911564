button {
    padding: 0;
}

.voteButton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: white;
    border: 1px solid black;
    border-radius: 20px;

    height: 90px;
    width: 50%;
    min-width: 85px;
    max-width: 250px;

    box-sizing: border-box;
}

.bidBtn {
  /*font-family: 'PT Root UI';*/
  border-radius: 12px !important;
  margin-left: 0.6rem !important;
  margin-top: 3px;
  width: auto;
  padding: 10px 16px;
  height: 3rem;
  border: transparent;
  font-weight: bolder;
  letter-spacing: normal;
  font-size: 19px;
  transition: all 0.2s ease-in-out;
  
  background: #ea3628;
  color: white;
  min-width: 300px;
  
  border: 1px solid #000000;
  box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.25);  
}

.bidBtn:disabled {
  cursor: not-allowed;
}

.bidBtn:hover,
.bidBtn:active,
.bidBtn:focus .bidBtn:disabled {
  outline: none !important;
  box-shadow: none !important;
  /*background-color: #2125298a;*/
  background: white;
  color: #ea3628;
  
}

.bidBtn:disabled {
  background-color: gray !important;
}


@media (hover: hover) {
    .voteButton:enabled:hover:not(.selected){
        background: rgba(0, 0, 0, 0.1);
    }
}
.voteText{
    /*font-size: 1em;*/
    font-weight: bold;
}

.voteEmojiText{
    font-size: 2em;
}

.selected{
    border: 2px solid black;
    background: rgb(127, 197, 156, .4);
}

@media only screen and (min-width: 768px) {
    .voteButton{
        height: 110px;
    }
    .voteText{
        /*font-size: 1.5em;*/
        font-weight: bolder;
    }

    .voteEmojiText{
        font-size: 3em;
    }
}

@media (max-width: 992px) {
	.bidBtn {
	  min-width: 300px;
	}

}

@media (max-width: 568px) {
	.bidBtn {
	  min-width: 200px;
	}
}