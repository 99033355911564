.App {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.bgGrey{
  background-color: #d5d7e1;
}

.bgBeige {
  background-color: #e1d7d5;
}

.nounWrapper {
  align-self: flex-end;
  width: 100%;
  text-align: center;
  font-family: 'PT Root UI', sans-serif;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 28px;  
}

.nounContentCol {
  display: flex;
  justify-content: center;
}

.auctionActivityCol {
  /*padding-right: 5rem;*/
  padding-bottom: 0rem;
  /*min-height: 520px;*/
  align-self: flex-end !important;
}

@media (max-width: 992px) {
  .nounWrapper {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
  }
  .auctionActivityCol {
    padding-top: 5%;
    padding-right: 5% !important;
    padding-left: 5% !important;
    width: 100%;    
    background: black;
    color: white;
  }
}

@media (max-width: 568px) {
  .auctionActivityCol {
    width: 100%;
    margin-left: unset;
    margin-right: unset;
    padding-bottom: 2rem;
    padding-top: 1rem;
    padding-right: unset;
    padding-left: 0;
    background: black;
    color: white;
  }

  .nounWrapper {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 2rem;
  }

  .nounContentCol {
    padding: 0rem;
  }
}
